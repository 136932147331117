import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { StudentActivityCoreService } from '../student-activity-core.service';

@Component({
    selector: 'app-json-viewer',
    templateUrl: './json-viewer.component.html',
    styleUrls: ['./json-viewer.component.css']
})

export class JsonViewerComponent implements OnInit {
    showResult = false;
    toggleEditor = 'disable';
    @ViewChild('JsonEditorComponent') editor: JsonEditorComponent | undefined;

    options = new JsonEditorOptions();
    resultOptions = new JsonEditorOptions();
    textareaInputData = '';
    annotationData = {};
    resultData = {};
    textareaResultData = '';
    currentUrl = '';
    resultStatus = '';

    constructor(private studentActivityCoreService: StudentActivityCoreService,
                private router: Router) {
        this.options.mode = 'code';
        this.resultOptions.mode = 'view';
        // options.modes = ['code', 'text', 'tree', 'view']; // Available modes
    }

    ngOnInit(): void {
        this.fetchInputJson(this.extractRoutes());
        if (sessionStorage.getItem('mode') === 'DataStore') {
            this.studentActivityCoreService.notifications().subscribe(
                (info: any) => {
                    if (info.event === 'onAPIRequestError') {
                        console.log('NNN EEE', info.message);
                        this.onStudentActivityError(info.message);
                    }
                }
            );
        }
    }

    extractRoutes(): any {
        const urlPath = this.router.url;
        const parentPath = urlPath.substring(1, urlPath.lastIndexOf('/'));
        const childPath = urlPath.substring(urlPath.lastIndexOf('/') + 1);
        return { parentPath, childPath };
    }

    onUrlChange(urlChange: any): any {
        this.showResult = false;
        this.fetchInputJson(urlChange);
    }

    fetchInputJson(urlObj: { parentPath: string, childPath: string }): any {
        this.studentActivityCoreService.getJsonData(urlObj).subscribe((data: {}) => {
            this.annotationData = data;
            this.textareaInputData = JSON.stringify(data, null, 4);
        });
    }

    onJsonSubmit(): any {
        let jsonData;
        const changeType = this.extractRoutes();
        if (this.toggleEditor === 'disable') {
            jsonData = JSON.parse(this.textareaInputData);
        } else {
            jsonData = this.editor?.get();
        }
        this.textareaResultData = '';
        this.resultData = {};
        this.showResult = true;
        this.resultStatus = 'PROCESSING...';
        this.studentActivityCoreService.submitData(jsonData, changeType)
            .subscribe(
                (response: any) => {
                    this.onStudentActivitySuccess(response);
                },
                (error: any) => {
                    this.onStudentActivityError(error);
                }
            );
    }

    onStudentActivitySuccess(response: any): void {
        console.log(response);
        this.textareaResultData = JSON.stringify(response, null, 4);
        this.resultData = response;
        this.showResult = true;
        this.resultStatus = 'SUCCESS';
    }

    onStudentActivityError(error: any): void {
        console.log(error);
        this.showResult = true;
        this.resultStatus = 'ERROR';
        this.textareaResultData = JSON.stringify(error, null, 4);
        this.resultData = error;
    }
}
