import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { CoreMode } from '@savvaslearning/user-activity-core/dist/types/requestTypes/core';
import { UserActivityCore } from '@savvaslearning/user-activity-core';
import { environment } from '../environments/environment';
import { LstUserService } from '@lst/lst-auth';
import Annotation from './input-data/annotation-data';
import NoteBook from './input-data/noteBook-data';
import Bookmark from './input-data/bookmark-data';
import Sco from './input-data/sco-data';
import { StudentActivityConfigOptionType } from '@savvaslearning/user-activity-core/dist/types/requestTypes/core';
import Desmos from './input-data/desmos-data';


@Injectable()
export class StudentActivityCoreService {
    userId: string | undefined;
    dataStoreStarted: boolean;
    private annotationSVC: any;
    private noteSVC: any;
    private bookmarkSVC: any;
    private scoSVC: any;
    private desmosSVC: any;
    constructor(private readonly lstUserService: LstUserService) {
        this.dataStoreStarted = false;
        const mode: string = sessionStorage.getItem('mode') || CoreMode.API;
        this.configure({ mode });
        this.lstUserService.rbsToken$.subscribe((token) => {
            this.userId = this.lstUserService.getCurrentUserId();
            if (!this.dataStoreStarted) {
                this.startDataStore({ token, userId: this.userId });
                this.dataStoreStarted = true;
            }
            UserActivityCore.authenticate({ userId: this.userId, token });
        });
    }

    notifications(): Observable<any> {
        return UserActivityCore.notifications();
    }

    private startDataStore({ token, userId }: { token: string, userId: string }): void {
        UserActivityCore.start({token, userId});
    }

    private configure({ mode }: {mode: string }): void {
        let configuration: StudentActivityConfigOptionType;
        const models = ['Annotation', 'Note', 'SCO', 'Bookmark', 'Desmos'];
        if (mode === 'DataStore') {
            configuration = {
                awsConfig: environment.awsConfig,
                models,
                token: '',
                mode: CoreMode.DataStore
            };
        } else {
            configuration = {
                awsConfig: environment.awsConfig,
                models,
                token: '',
                mode: CoreMode.API
            };
        }
        UserActivityCore.configure(configuration);
        this.annotationSVC = UserActivityCore.Annotation();
        this.noteSVC = UserActivityCore.NoteBook();
        this.bookmarkSVC = UserActivityCore.Bookmark();
        this.scoSVC = UserActivityCore.Sco();
        this.desmosSVC= UserActivityCore.Desmos();
    }

    getJsonData(urlData: { parentPath: string, childPath: string }): any {
        let fetchedData;
        switch (urlData.parentPath) {
            case 'annotation':
                fetchedData = this.fetchAnnotationData(urlData.childPath);
                break;
            case 'bookmark':
                fetchedData = this.fetchBookmarkData(urlData.childPath);
                break;
            case 'notebook':
                fetchedData = this.fetchNoteBookData(urlData.childPath);
                break;
            case 'sco':
                fetchedData = this.fetchScoData(urlData.childPath);
                break;
            case 'desmos':
                fetchedData = this.fetchDesmosData(urlData.childPath);
                break;
        }
        return of(fetchedData);
    }

    private fetchAnnotationData(annotationType: string): any {
        switch (annotationType) {
            case 'create':
                return { ...Annotation.CREATE(),  userId: this.userId };
            case 'update':
                return { ...Annotation.UPDATE(), userId: this.userId };
            case 'annotationsForPage':
                return { ...Annotation.BY_USER_BOOK_ID_PAGE_URI(), userId: this.userId };
            case 'annotationsForBook':
                return { ...Annotation.BY_User_BOOK_ID(), userId: this.userId };
            case 'delete':
                return { ...Annotation.DELETE(), userId: this.userId  };
        }
    }

    private fetchBookmarkData(bookMarkType: string): any {
        switch (bookMarkType) {
            case 'create':
                return { ...Bookmark.CREATE(), userId: this.userId };
            case 'bookMarksForBook':
                return { ...Bookmark.BOOKMARKS_FOR_BOOK(), userId: this.userId };
            case 'delete':
                return { ...Bookmark.DELETE(), userId: this.userId };
        }
    }

    private fetchNoteBookData(noteBookType: string): any {
        switch (noteBookType) {
            case 'create':
                return { ...NoteBook.CREATE(), userId: this.userId };
            case 'update':
                return { ...NoteBook.UPDATE(), userId: this.userId };
            case 'get_all_notes':
                return { ...NoteBook.BY_USER_BOOK_ID(), userId: this.userId };
            case 'get_all_notes_for_page':
                return { ...NoteBook.BY_USER_PAGE(), userId: this.userId };
            case 'get_note_by_object_id':
                return { ...NoteBook.BY_USER_OBJECT_ID(), userId: this.userId };
            case 'get_all_notes_for_page_uri':
                return { ...NoteBook.BY_USER_PAGE_URI(), userId: this.userId };
            case 'delete':
                return { ...NoteBook.DELETE(), userId: this.userId };
            case 'save_note':
                return { ...NoteBook.SAVE_NOTE(), userId: this.userId };
            case 'toggle_archive':
                return { ...NoteBook.TOOGLE_ARCHIVE(), userId: this.userId };
        }
    }

    private fetchScoData(scoType: string): any {
        switch (scoType) {
            case 'create':
                return { ...Sco.CREATE(), userId: this.userId };
            case 'create_with_image':
                return { ...Sco.CREATE_WITH_IMAGE(this.userId || '') };
            case 'create_with_multiple_image':
                return { ...Sco.CREATE_WITH_MULTI_IMAGE(this.userId || '') };
            case 'update':
                return { ...Sco.UPDATE(), userId: this.userId };
            case 'scoByActivityStateIdentifier':
                return { ...Sco.SCO_BY_ACTIVITY_STATE_IDENTIFIER(), userId: this.userId };
        }
    }

    private fetchDesmosData(desmosType: string): any {
        switch (desmosType) {
            case 'create':
                return { ...Desmos.CREATE(),  userId: this.userId };
            case 'update':
                return { ...Desmos.UPDATE(), userId: this.userId };
            case 'desmosByUserUpdateAt':
                return { ...Desmos.GET_DESMOS_BY_USER_UPDATEAT(), userId: this.userId };
            case 'desmosById':
                return { ...Desmos.GET_DESMOS_BY_ID(), userId: this.userId };
            case 'delete':
                return { ...Desmos.DELETE(), userId: this.userId  };
        }
    }

    submitData(data: any, route: any): Observable<any> {
        switch (route.parentPath) {
            case 'annotation':
                const annotationSVC = this.annotationSVC;
                switch (route.childPath) {
                    case 'create':
                        return annotationSVC.create(data);
                    case 'update':
                        return annotationSVC.update(data);
                    case 'annotationsForPage':
                        return annotationSVC.annotationsForPage(data);
                    case 'annotationsForBook':
                        return annotationSVC.annotationsForBook(data);
                    case 'delete':
                        return annotationSVC.delete(data);
                }
                break;
            case 'notebook':
                const noteSVC = this.noteSVC;
                switch (route.childPath) {
                    case 'create':
                        return noteSVC.create(data);
                    case 'update':
                        return noteSVC.update(data);
                    case 'get_all_notes':
                        return noteSVC.getAllNotes(data);
                    case 'get_all_notes_for_page':
                        return noteSVC.getNotesForPage(data);
                    case 'get_all_notes_for_page_uri':
                        return noteSVC.getNoteByUserBookIdPageUris(data);
                    case 'get_note_by_object_id':
                        return noteSVC.getNoteByObjectId(data);
                    case 'delete':
                        return noteSVC.delete(data);
                    case 'save_note':
                        return noteSVC.save(data);
                    case 'toggle_archive':
                        return noteSVC.toggleArchive(data);
                }
                break;
            case 'bookmark':
                const bookmarkSVC = this.bookmarkSVC;
                switch (route.childPath) {
                    case 'create':
                        return bookmarkSVC.create(data);
                    case 'bookMarksForBook':
                        return bookmarkSVC.bookmarksForBook(data);
                    case 'delete':
                        return bookmarkSVC.delete(data);
                }
                break;
            case 'sco':
                const scoSVC = this.scoSVC;
                switch (route.childPath) {
                    case 'create_with_multiple_image':
                    case 'create_with_image':
                        return scoSVC.create(data);
                        break;
                    case 'create':
                        return scoSVC.create(data);
                        break;
                    case 'update':
                        return scoSVC.update(data);
                        break;
                    case 'scoByActivityStateIdentifier':
                        return scoSVC.scoByActivityStateIdentifier(data);
                        break;
                }
                break;
            case 'desmos':
                const desmosSVC = this.desmosSVC;
                switch (route.childPath) {
                    case 'create':
                        return desmosSVC.create(data);
                        break;
                    case 'delete':
                        return desmosSVC.delete(data);
                        break;
                    case 'update':
                        return desmosSVC.update(data);
                        break;
                    case 'desmosByUserUpdateAt':
                        return desmosSVC.getDesmosByUserUpdateAt(data);
                        break;
                    case 'desmosById':
                        return desmosSVC.getDesmosById(data);
                        break;
                }
                break;
        }
        return from([]);
    }
}
