import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LstAuthService, LstUserService } from '@lst/lst-auth';
import { first } from 'rxjs/operators';
import * as data from '../tabs-data.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() urlChangeEvent = new EventEmitter<{}>();

  firstLevelTabs = data.firstLevelTabs;
  secondLevelTabs = data.secondLevelTabs;
  selectedUrl = 'notebook';
  appSyncMode = '';
  userName: string;

  constructor(private router: Router,
              private lstUserService: LstUserService,
              private lstAuthService: LstAuthService
    ) {
      this.userName = `${this.lstUserService.getFirstName()} ${this.lstUserService.getLastName()}`
    }

  ngOnInit(): void {
    const urlPath = this.router.url;
    const urlContainsChildRoute = urlPath.lastIndexOf('/');
    this.appSyncMode = sessionStorage.getItem('mode') || 'API';
    if (urlContainsChildRoute === -1) {
      this.selectedUrl = urlPath.substring(1);
    } else {
      this.selectedUrl = urlPath.substring(1, urlContainsChildRoute);
    }
  }

  onChildTabClick(parentPath: string, childPath: string): any {
    this.urlChangeEvent.emit({parentPath, childPath});
  }

  returnZero(): number {
    return 0;
  }

  onLogout(): any {
    sessionStorage.removeItem('mode');
    this.lstAuthService.redirectToSSOLogoutPage()
            .pipe(first())
            .subscribe();
  }
}
