<app-header (urlChangeEvent)="onUrlChange($event)"></app-header>

<div class="container editorSelect">
    <mat-form-field appearance="fill">
        <mat-label>Select the editor</mat-label>
        <mat-select [(value)]="toggleEditor">
            <mat-option value="enable">Enable JSON Editor</mat-option>
            <mat-option value="disable">Disable JSON Editor</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutWrap *ngIf="toggleEditor==='disable'">
    <div fxFlex="2%"></div>
    <div fxFlex="45%">
        <div id="jsonEditorInput">
            <mat-card [style.backgroundColor]="'#e98639c9'">Input JSON
                <button mat-raised-button color="primary" id="json_submit" (click)="onJsonSubmit()">Submit</button>
            </mat-card>
            <textarea name="textarea_input" id="jsonTextarea_input" cols="73" rows="35"
                [(ngModel)]="textareaInputData"></textarea>
        </div>
    </div>
    <div fxFlex="5%"></div>
    <div fxFlex="45%" *ngIf="showResult">
        <mat-card class="resultSection">Result:
            <b [ngStyle]="{'color': (resultStatus === 'SUCCESS') ? 'green' : 'red' }">
                {{resultStatus}}
            </b>
        </mat-card>
        <textarea name="textarea_result" id="jsonTextarea_output" cols="73" rows="35"
            readonly>{{textareaResultData}}</textarea>
    </div>
</div>

<div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutWrap *ngIf="toggleEditor==='enable'">
    <div fxFlex="2%"></div>
    <div fxFlex="45%">
        <div id="jsonEditorInput">
            <mat-card [style.backgroundColor]="'#e98639c9'">Input JSON
                <button mat-raised-button color="primary" id="json_submit" (click)="onJsonSubmit()">Submit</button>
            </mat-card>
            <json-editor #JsonEditorComponent [options]="options" [data]="annotationData" class="jsonEditor">
            </json-editor>
        </div>
    </div>
    <div fxFlex="5%"></div>
    <div fxFlex="45%" *ngIf="showResult">
        <mat-card class="resultSection">Result:
            <b [ngStyle]="{'color': (resultStatus === 'SUCCESS') ? 'green' : 'red' }">
                {{resultStatus}}
            </b>
        </mat-card>
        <json-editor [options]="resultOptions" [data]="resultData" id="jsonEditorOutput" class="jsonEditor">
        </json-editor>
    </div>
</div>