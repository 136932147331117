<nav mat-tab-nav-bar>
    <ng-container *ngFor="let item of firstLevelTabs | keyvalue: returnZero">
        <a mat-tab-link routerLink="/{{item.key}}"
            routerLinkActive="active-link">{{item.value}}
        </a>
    </ng-container>
    <p class="sync-mode">{{ appSyncMode }}</p>
    <button class="sign_out_page"(click)="onLogout()">Sign Out</button>
    <p class="user-info">{{ userName }} </p>
</nav>
<nav mat-tab-nav-bar>
    <ng-container *ngFor="let item of secondLevelTabs | keyvalue">
        <ng-container *ngFor="let tabItem of item.value | keyvalue: returnZero">
            <a mat-tab-link *ngIf="selectedUrl===item.key" routerLink="../{{tabItem.key}}"
                routerLinkActive="active-link" (click)="onChildTabClick(item.key, tabItem.key)">{{tabItem.value}}
            </a>
        </ng-container>
    </ng-container>
</nav>
