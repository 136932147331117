import {
  CreateAnnotationType,
  UpdateAnnotationType,
  DeleteAnnotationType,
  AnnotationByUserBookIdPageUriType,
  AnnotationByUserBookIdType,
  Action,
  ActionType
} from '@savvaslearning/user-activity-core/dist/types/requestTypes/annotation';


export default class Annotation {
  public static CREATE(): CreateAnnotationType {
    return {
      action: Action.edit,
      pageUri: 'OPS/xhtml/chem.xhtml#P70010165230000000000000000058FB',
      bookId: '3AGPO8XKBV',
      activityBookId: '3AGPO8XKBV',
      activityPageId: 'someRandomString',
      activitySectionId: 'section-1',
      actionType: ActionType.annotation,
      annotationDttm: new Date().getTime().toString(),
      color: 'hg_0000FF',
      colorCode: 0,
      labels: ['Document'],
      noteText: '',
      ranges: [{
        end: '/section[1]/section[1]/p[1]',
        endOffset: 175,
        start: '/section[1]/section[1]/p[1]',
        startOffset: 166
      }],
      selectedText: 'particles',
      selectionType: 'solid',
      userId: ''
    };
  }

  public static UPDATE(): UpdateAnnotationType {
    return {
      id: '',
      objectId: '',
      userId: '',
      action: Action.edit,
      actionType: ActionType.annotation,
      color: 'hg_0000FF',
      colorCode: 110,
      labels: ['Document'],
      noteText: '',
      selectionType: 'solid',
      _version: 1,
    };
  }

  public static BY_USER_BOOK_ID_PAGE_URI(): AnnotationByUserBookIdPageUriType {
    return {
      bookId: '3AGPO8XKBV',
      pageUri: 'OPS/xhtml/chem.xhtml#P70010165230000000000000000058FB',
      userId: '',
    };
  }

  // tslint:disable-next-line:variable-name
  public static BY_User_BOOK_ID(): AnnotationByUserBookIdType {
    return {
      bookId: '3AGPO8XKBV',
      userId: '',
    };
  }

  public static DELETE(): DeleteAnnotationType {
    return {
      id: '',
      _version: 1
    };
  }
}
