export const environment = {
    production: false,
    authContextId: '6065',
    appName: 'demo-app',
    appId: 'webapp',
    rbsConfig: {
        authGatewayUrl: 'https://rr-sapi.savvasrealize.com/sapi',
        scope: 'rbs',
        clientId: 'L72o4UuKHtBzGNPIj8KQegf3AYFlVR7R',
        grant_type: 'custom_castgc'
    },
    realizeBaseUrl: 'https://savvasrealize.com/community',
    ssoUrl: 'https://sso.rumba.pk12ls.com/sso',
    ssoLoginQueryParams: {
        k12int: true,
    },
    awsConfig : {
        aws_project_region: 'us-east-1',
        aws_appsync_graphqlEndpoint: 'https://hggyurlfzbbglc4qk3agfarhqa.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'API_KEY',
        aws_appsync_apiKey: 'da2-3okq3ypsnvhzhkbntv5bdpulu4',
        aws_cloud_logic_custom: [
            {
                name: 'sapiCognitoUser',
                endpoint: 'https://lspgmwcj5j.execute-api.us-east-1.amazonaws.com/prod',
                region: 'us-east-1'
            }
        ],
        Auth: {
            identityPoolId: 'us-east-1:759a51fa-6b89-4b8b-82cc-53d319885873',
            region: 'us-east-1',
        },
        Storage: {
            AWSS3: {
                'bucket': '${USER_ACTIVITY_SERVICE_SCO_IMAGE_BUCKET}',
                'region': 'us-east-1'
            }
        }
    }
}
