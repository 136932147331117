import { BookmarkType, CreateBookmarkType,
    DeleteBookmarkType,
    } from '@savvaslearning/user-activity-core/dist/types/requestTypes/bookmark';

export default class Bookmark {
    public static CREATE(): CreateBookmarkType  {
        return {
            bookId: 'MFMSO138UR',
            userId: '',
            pageUri: 'OPS/xhtml/g9u1_01_unitopener.xhtml#P700101008100000000000000015D55D',
            title: 'Unit Introduction',
            isDownloaded: true,
            activityBookId: 'MFMSO138UR',
            activityPageId: 'MFMSO138UR-pageId'
        };
    }

    public static DELETE(): DeleteBookmarkType  {
        return {
            id: '3b671fc2-35dc-4af3-83e7-e6397b0a6cd0',
            _version: 1,
        };
    }

    public static BOOKMARKS_FOR_BOOK(): BookmarkType  {
        return {
            bookId: 'MFMSO138UR',
            userId: ''
        };
    }
}
