import { CreateDesmosType, DeleteDesmosType, DesmosById, UpdateDesmosType } from "@savvaslearning/user-activity-core/dist/types/requestTypes/desmos";

export default class Desmos {
    public static CREATE(): CreateDesmosType {
        return {
            objectId:"override-in-server",
            calcState:"{\"version\":5,\"graph\":{\"viewport\":{\"xmin\":-18.257661040206354,\"ymin\":-10.334055145037834,\"xmax\":23.14013879538412,\"ymax\":8.927421167354957}},\"expressions\":{\"list\":[{\"type\":\"expression\",\"id\":\"1\",\"color\":\"#c74440\",\"latex\":\"3x+2\\\\left\\\\{x<4\\\\right\\\\}\",\"hidden\":true,\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"3\",\"color\":\"#c74440\",\"latex\":\"\\\\left(4,14\\\\right)\",\"hidden\":true,\"pointStyle\":\"OPEN\",\"dragMode\":\"NONE\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"2\",\"color\":\"#2d70b3\",\"latex\":\"-\\\\frac{1}{2}x+5\\\\left\\\\{x\\\\ge7\\\\right\\\\}\",\"hidden\":true,\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"4\",\"color\":\"#2d70b3\",\"latex\":\"\\\\left(7,\\\\frac{3}{2}\\\\right)\",\"hidden\":true,\"dragMode\":\"NONE\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"5\",\"color\":\"#6042a6\",\"latex\":\"x-1\\\\left\\\\{x<n\\\\right\\\\}\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"6\",\"color\":\"#2d70b3\",\"latex\":\"n=-3\",\"hidden\":true,\"sliderHardMin\":true,\"sliderHardMax\":true,\"sliderMin\":\"-20\",\"sliderMax\":\"20\",\"sliderInterval\":\"1\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"7\",\"color\":\"#2d70b3\",\"latex\":\"-x+4\\\\left\\\\{x\\\\ge n\\\\right\\\\}\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"8\",\"color\":\"#fa7e19\",\"style\":\"SOLID\"}]}}",
            folderName:"folder3",
            folderType:"type3",
            title:"Test Desmos4",
            type:"type3",
            userId: '',
            _ttl: 1
            // userId:"ffffffff578fd545e4b0db858de71ab2
        };
    }

    public static UPDATE(): UpdateDesmosType {
        return {
            id: "0f800b7b-b965-4e71-97d4-3ff5e6c84c0b",
            objectId:"7d47438d70757647d91c1e7757e39fb51216335e1dd9fb7cbdcbdaaa4c214341",
            calcState: "{\"version\":5,\"graph\":{\"viewport\":{\"xmin\":-18.257661040206354,\"ymin\":-10.334055145037834,\"xmax\":23.14013879538412,\"ymax\":8.927421167354957}},\"expressions\":{\"list\":[{\"type\":\"expression\",\"id\":\"1\",\"color\":\"#c74440\",\"latex\":\"3x+2\\\\left\\\\{x<4\\\\right\\\\}\",\"hidden\":true,\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"3\",\"color\":\"#c74440\",\"latex\":\"\\\\left(4,14\\\\right)\",\"hidden\":true,\"pointStyle\":\"OPEN\",\"dragMode\":\"NONE\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"2\",\"color\":\"#2d70b3\",\"latex\":\"-\\\\frac{1}{2}x+5\\\\left\\\\{x\\\\ge7\\\\right\\\\}\",\"hidden\":true,\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"4\",\"color\":\"#2d70b3\",\"latex\":\"\\\\left(7,\\\\frac{3}{2}\\\\right)\",\"hidden\":true,\"dragMode\":\"NONE\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"5\",\"color\":\"#6042a6\",\"latex\":\"x-1\\\\left\\\\{x<n\\\\right\\\\}\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"6\",\"color\":\"#2d70b3\",\"latex\":\"n=-3\",\"hidden\":true,\"sliderHardMin\":true,\"sliderHardMax\":true,\"sliderMin\":\"-20\",\"sliderMax\":\"20\",\"sliderInterval\":\"1\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"7\",\"color\":\"#2d70b3\",\"latex\":\"-x+4\\\\left\\\\{x\\\\ge n\\\\right\\\\}\",\"style\":\"SOLID\"},{\"type\":\"expression\",\"id\":\"8\",\"color\":\"#fa7e19\",\"style\":\"SOLID\"}]}}",
            title: "Test Desmos1 updated 2",
            type: 'type3',
            createdAt: '',
            updatedAt: '',
            _ttl: 1,
            _version: 2,
        };
    }

    public static DELETE(): DeleteDesmosType {
        return {
            id: "0f800b7b-b965-4e71-97d4-3ff5e6c84c0b",
            userId: ''
        };
    }

    public static GET_DESMOS_BY_ID(): DesmosById {
        return {
            objectId: "7d47438d70757647d91c1e7757e39fb51216335e1dd9fb7cbdcbdaaa4c214341",
        };
    }

    public static GET_DESMOS_BY_USER_UPDATEAT(): any {
        return {};
    }
}
