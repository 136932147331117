import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LstAuthGuard, LstRouterModule } from '@lst/lst-auth';
import { JsonViewerComponent } from './json-viewer/json-viewer.component';
import { ModeSelectorComponent } from './mode-selector/mode-selector.component';

const routes: Routes = [
    { path: '', component: ModeSelectorComponent },
    { path: 'notebook', redirectTo: '/notebook/create', pathMatch: 'full' },
    { path: 'notebook/:name', component: JsonViewerComponent, canActivate: [LstAuthGuard]  },
    { path: 'bookmark', redirectTo: '/bookmark/create', pathMatch: 'full' },
    { path: 'bookmark/:name', component: JsonViewerComponent, canActivate: [LstAuthGuard]  },
    { path: 'annotation', redirectTo: '/annotation/create', pathMatch: 'full' },
    { path: 'annotation/:name', component: JsonViewerComponent, canActivate: [LstAuthGuard] },
    { path: 'sco', redirectTo: '/sco/create', pathMatch: 'full' },
    { path: 'sco/:name', component: JsonViewerComponent, canActivate: [LstAuthGuard]  },
    { path: 'desmos', redirectTo: '/desmos/create', pathMatch: 'full' },
    { path: 'desmos/:name', component: JsonViewerComponent, canActivate: [LstAuthGuard]  },
    { path: '**', redirectTo: '/notebook/create' },
];
@NgModule({
    imports: [
        LstRouterModule.getRoutes(),
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
