import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderComponent } from './header/header.component';
import { JsonViewerComponent } from './json-viewer/json-viewer.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { environment } from 'src/environments/environment';
import { LstAuthModule, LstAuthModuleOptions } from '@lst/lst-auth';
import { StudentActivityCoreService } from './student-activity-core.service';
import { ModeSelectorComponent } from './mode-selector/mode-selector.component';

const lstModuleOptions: LstAuthModuleOptions = {
    appId: environment.appId,
    authGatewayBaseUrl: environment.rbsConfig.authGatewayUrl,
    authContextId: environment.authContextId,
    authGatewayClientId: environment.rbsConfig.clientId,
    rbsTokenScope: environment.rbsConfig.scope,
    ssoBaseUrl: environment.ssoUrl,
    rbsTokenGrantType: environment.rbsConfig.grant_type,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    JsonViewerComponent,
    ModeSelectorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    NgJsonEditorModule,
    LstAuthModule.forRoot(lstModuleOptions)
  ],
  providers: [
    StudentActivityCoreService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
