import {
    CreateNoteType, UpdateNoteType,
    DeleteNoteType, NotesForPageReqType,
    ContentType,
    NoteObjectId, NotesForBookReqType, NotesForPageURIsType
} from '@savvaslearning/user-activity-core/dist/types/requestTypes/notebook';

export default class NoteBook {
    public static CREATE(): CreateNoteType {
        return {
            app: 'etext2',
            autoSave: true,
            bookIndexId: '7519558a9bd6a558e2c464d1382c511f',
            content: 'note33',
            contentType: ContentType.n,
            bookContext: 'VLV6NM44M',
            pageContext: 'OPS/xhtml/g9u1_03e_performance_task.xhtml#P7001010081000000000000000012163',
            activityBookId: 'VLV6NM44M',
            activityPageId: 'OPS/xhtml/g9u1_03e_performance_task.xhtml#P7001010081000000000000000012163',
            userId: '',
            archived: false
        };
    }

    public static UPDATE(): UpdateNoteType {
        return {
            id: 'b3a2a5a0-9f18-4a0f-882e-6a973a90838b',
            content: 'content--298+1',
            objectId: 'd85c4778-5d66-4963-ab33-7410d0dd4548',
            saved: true,
            userId: '',
            _version: 1
        };
    }

    public static BY_USER_BOOK_ID(): NotesForBookReqType {
        return {
            app: 'etext2',
            bookId: 'bd349433c522cce5fbd3013a8d8c8420',
            userId: ''
        };
    }

    public static BY_USER_PAGE(): NotesForPageReqType {
        return {
            app: 'etext2',
            bookId: 'VLV6NM44M',
            pageId: 'OPS/xhtml/g9u1_03e_performance_task.xhtml#P7001010081000000000000000012163',
            userId: 'ffffffff5ab00b8af856993c2720dc97'
        };
    }

    public static BY_USER_OBJECT_ID(): NoteObjectId {
        return {
            objectId: '2f4daf69-c05a-4510-84e0-738c20a514e8',
            userId: '',
        };
    }

    public static DELETE(): DeleteNoteType {
        return {
            id: '82e46ab2-08c5-4bcc-8c22-266eb9fb3bc5',
            _version: 1,
        };
    }

    public static BY_USER_PAGE_URI(): NotesForPageURIsType {
        return {
            app: 'etext2',
            bookId: 'VLV6NM44M',
            pageUris: ['OPS/xhtml/g9u1_03e_performance_task.xhtml#P7001010081000000000000000012163'],
            userId: '',
        };
    }

    public static SAVE_NOTE(): UpdateNoteType {
        return {
            id: 'b3a2a5a0-9f18-4a0f-882e-6a973a90838b',
            content: 'content--298+1',
            objectId: 'd85c4778-5d66-4963-ab33-7410d0dd4548',
            saved: true,
            userId: '',
            _version: 1
        };
    }

    public static TOOGLE_ARCHIVE(): UpdateNoteType {
        return {
            id: 'b3a2a5a0-9f18-4a0f-882e-6a973a90838b',
            content: 'content--298+1',
            objectId: 'd85c4778-5d66-4963-ab33-7410d0dd4548',
            saved: true,
            userId: '',
            _version: 1
        };
    }
}
