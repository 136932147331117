import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mode-selector',
  templateUrl: './mode-selector.component.html',
  styleUrls: ['./mode-selector.component.css']
})
export class ModeSelectorComponent implements OnInit {
  modeSelection = '';

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onSubmit(): void {
    sessionStorage.setItem('mode', this.modeSelection);
    this.router.navigate(['/notebook/create']);
  }

}
