import { CreateScoType, ScoByActivityStateType, UpdateScoType } from '@savvaslearning/user-activity-core/dist/types/requestTypes/sco';

export enum XApiActorTypes {
    Agent = 'Agent',
    Group = 'Group',
}
export default class Sco {
    public static CREATE(): CreateScoType {
        return {
            userId: 'ffffffff5ab00b8af856993c2720dc97',
            activityBookId: '18zi6o8s63b',
            activityId: 'http://dcat.pearson.com/dcatweb/view/A0472421',
            agent: {
                objectType: XApiActorTypes.Agent,
                name: 'tefstName',
                openid: 'http://rumba.peasoncmg.com',
                mbox_sha1sum: 'tesdMbox_sha1suma',
                account: {
                    homePage: 'http://rumba.pearsoncmg.com',
                    name: 'ffffffff5ab00b8af856993c2720dc97',
                }
            },
            registration: '18zi6o8s63b-3-a0472421-ffffffff5ab00b8af856993c2720dc97',
            activityPageId: 'testActivityPageId',
            agentAccountHomePage: 'testAgentAccountHomePage',
            agentAccountName: 'testAgentAccountName',
            stateId: 'state',
            className: 'testClassName',
            jsonPayload: '{"result":{"score":{"scaled":1,"raw":0,"max":0},"successStatus":"passed","progressMeasure":1,"completionStatus":"completed","location":"s0_p3","totalTime":480.852,"exit":"suspend"},"exit":"suspend","session":{"id":"ST-1226653-ufYfk6sKQIiDfsIQnjc2-nightly-cas-caschart-79754f56cf-5k72z"},"suspendData":{"s0_p3":{"pageDone":0}},"s0_p3_r2-g22":{"scores":[{"desc":"Table is presented to the user.","scaled":1,"raw":"0.000","max":0,"progressMeasure":1,"successStatus":"passed","completionStatus":"completed"}],"data":{"learnerResponses":["Edited/Viewed Table"],"correctResponses":[[{"pattern":"Edited/Viewed Table"}]],"suspendData":{"isLocked":false,"e_3_":"test sco","f_3_":"","e_4_":"","f_4_":"","e_6_":"","f_6_":"","e_7_":"","f_7_":"","e_9_":"","f_9_":"","e_11_":"","f_11_":"","e_12_":"","f_12_":"","resetBtn":"enable","ansBtn":"enable","chartItBtn":"enable","numAddedRows":0,"addedRowIds":[],"nextId":12}}}}'
        };
    }

    public static CREATE_WITH_IMAGE(userId: string): CreateScoType {
        const random = Math.floor(Math.random() * 1000);
        const registration = `18zi6o8s63b-3-a047${random}`;
        return {
            userId,
            activityBookId: '18zi6o8s63b',
            activityId: 'http://dcat.pearson.com/dcatweb/view/A0472421',
            agent: {
                objectType: XApiActorTypes.Agent,
                name: 'tefstName',
                openid: 'http://rumba.peasoncmg.com',
                mbox_sha1sum: 'tesdMbox_sha1suma',
                account: {
                    homePage: 'http://rumba.pearsoncmg.com',
                    name: userId,
                }
            },
            registration: `${registration}-${userId}`,
            activityPageId: 'testActivityPageId',
            agentAccountHomePage: 'testAgentAccountHomePage',
            agentAccountName: 'testAgentAccountName',
            stateId: 'state',
            className: 'testClassName',
            jsonPayload: '{"s0_p1_d0":{"scores":[],"data":{"suspendData":{"image":"base64-Data","shapeData":[{"height":191,"imageUrl":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4z8AAAAMBAQDJ/pLvAAAAAElFTkSuQmCC"},{"description":"Equilateral Triangle","height":106,"imageUrl":"css/product/shapes/shapes/shapes_equilateral.svg"}]}}}}'
        };
    }

    public static CREATE_WITH_MULTI_IMAGE(userId: string): CreateScoType {
        const random = Math.floor(Math.random() * 1000);
        const registration = `18zi6o8s63b-3-a047${random}`;
        return {
            userId,
            activityBookId: '18zi6o8s63b',
            activityId: 'http://dcat.pearson.com/dcatweb/view/A0472421',
            agent: {
                objectType: XApiActorTypes.Agent,
                name: 'tefstName',
                openid: 'http://rumba.peasoncmg.com',
                mbox_sha1sum: 'tesdMbox_sha1suma',
                account: {
                    homePage: 'http://rumba.pearsoncmg.com',
                    name: userId,
                }
            },
            registration: `${registration}-${userId}`,
            activityPageId: 'testActivityPageId',
            agentAccountHomePage: 'testAgentAccountHomePage',
            agentAccountName: 'testAgentAccountName',
            stateId: 'state',
            className: 'testClassName',
            jsonPayload: '{"s0_p1_d0":{"scores":[],"data":{"suspendData":{"image":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGNgYPgPAAEDAQAIicLsAAAAAElFTkSuQmCC","shapeData":[{"height":191,"imageUrl":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4z8AAAAMBAQDJ/pLvAAAAAElFTkSuQmCC"},{"description":"Equilateral Triangle","height":106,"imageUrl":"css/product/shapes/shapes/shapes_equilateral.svg"}]}}}}'
        };
    }

    public static UPDATE(): UpdateScoType {
        return {
            _version: 1,
            id: '1376d7c7-3e41-428d-be16-3e1e77979583',
            userId: 'ffffffff5ab00b8af856993c2720dc97',
            objectId: 'feba4cbb73636c1284ab56d8b98a456fab1537b09bc62efb42dc5eac2e6ede3b',
            jsonPayload: '{"session":{"id":"ST-1226653333-ufYfk6sKQIiDfsIQnjc2-nightly-cas-caschart-79754f56cf-5k72z"},"result":{"score":{"scaled":0.42335},"successStatus":"passed","progressMeasure":1,"completionStatus":"incomplete","location":"s0_p0","totalTime":21469.932000000004,"exit":"suspend"},"suspendData":{"s0_p0":{"pageDone":0}},"s0_p0_r2-g11":{"scores":[{"desc":"A video player presents a single video.","scaled":0,"raw":0,"max":0,"progressMeasure":0,"successStatus":"unknown","completionStatus":"not attempted"}],"data":{"learnerResponses":["Not Watched Video"],"correctResponses":[[{"pattern":"Watched Video"}]]}},"exit":"suspend"}',
        };
    }

    public static SCO_BY_ACTIVITY_STATE_IDENTIFIER(): ScoByActivityStateType {
        return {
            userId: 'ffffffff5ab00b8af856993c2720dc97',
            activityId: 'http://dcat.pearson.com/dcatweb/view/A0472421',
            agent: {
                objectType: XApiActorTypes.Agent,
                name: 'tefstName',
                openid: 'http://rumba.peasoncmg.com',
                mbox_sha1sum: 'tesdMbox_sha1suma',
                account: {
                    homePage: 'http://rumba.pearsoncmg.com',
                    name: 'ffffffff5ab00b8af856993c2720dc97',
                }
            },
            registration: '18zi6o8s63a-3-a0472421-ffffffff5ab00b8af856993c2720dc97',
            stateId: 'state'
        };
    }
}
